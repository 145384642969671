(function () {
  'use strict';

  class GroupReportCtrl {
    constructor(Utils, Groups, $window) {
      let vm = this;
      vm.ctrlName = 'GroupReportCtrl';
      vm.Utils = Utils;
      vm.Groups = Groups;
      vm.barChartOptions = {
        chart: {
          type: 'multiBarHorizontalChart',
          height: 450,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          showControls: true,
          showValues: true,
          valueFormat: function (d) {
            return $window.d3.format(',d')(d);
          },
          margin: {
            left: 150,
            right: 100
          },
          xAxis: {
            showMaxMin: false
          },
          yAxis: {
            axisLabel: 'Amount',
            tickFormat: function (d) {
              return $window.d3.format(',d')(d);
            }
          }
        }
      };
      vm.init();
    }
    init() {
      let vm = this;
      if (vm.Utils.roleCheck(['Teacher'])) {
        vm.Groups.myGroups()
          .then((data)=> {
            vm.groupsIndex = data;
          });
      } else {
        vm.Groups.groupsIndex()
          .then((data)=> {
            vm.groupsIndex = data;
          });
      }
    }
    report() {
      let vm = this;
      if (!vm.group_id) {
        return;
      }
      vm.data = [
        {
          key: 'Resource Item Access',
          color: '#36c5cd',
          values: []
        },
        {
          key: 'Discussion Items Logged',
          color: '#057feb',
          values: []
        }
      ];
      vm.Groups.metrics({id: vm.group_id})
        .then((data)=> {
          vm.reportData = data;
          angular.forEach(vm.reportData.metrics, function (v) {
            vm.data[1].values.push({
              label: v.student,
              value: v.discussion_entries
            });
            vm.data[0].values.push({
              label: v.student,
              value: v.resouce_node_views
            });
          });
        });
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:GroupReportCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('GroupReportCtrl', GroupReportCtrl);
}());
